import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import useAnimationFrame from '../utils/hooks/useAnimationFrame';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Clock from './clock';
import CurrentTime from '../components/CurrentTime';
import QuoteCarousel from '../components/QuoteCarousel';
import Questions from '../components/Questions';
import Hotlines from '../components/Hotlines';

import styles from '../styles/home.module.scss';

export const query = graphql`
	query {
		allContentfulEmotion(sort: { fields: [hour], order: ASC }) {
			nodes {
				id
				name
				hour
				color
				dilutedName
				concentratedName
				quotes {
					id
					author
					body {
						raw
					}
				}
				questions {
					id
					body {
						raw
					}
				}
			}
		}
		allContentfulHotline(sort: { fields: [ordinal], order: ASC }) {
			nodes {
				name
				phone
				textPhone
				textNum
				textBody
				ordinal
			}
		}
		allContentfulPage(sort: { fields: [slug], order: ASC }) {
			nodes {
				title
				slug
				body {
					raw
				}
			}
		}
	}
`;

const calcHour = militaryHour => {
	if (!militaryHour) return 8;
	if (militaryHour <= 8) return militaryHour;
	return calcHour(militaryHour - 8);
};

const IndexPage = ({ data }) => {
	const emotions = data.allContentfulEmotion.nodes;
	const hotlines = data.allContentfulHotline.nodes;

	const [date, setDate] = React.useState(null);
	const [loaded, setLoaded] = React.useState(false);

	const hour = calcHour(date?.getHours());
	const currentEmotion = emotions.find(emotion => emotion.hour === hour);

	useAnimationFrame(() => {
		setDate(new Date());
		setLoaded(true);
	});

	if (typeof window !== 'object' || !loaded) return 'Loading…';

	return (
		<Layout isHome>
			<SEO title="A.S. King Switch Emotion Clock" />
			<CurrentTime area="time" currentEmotion={currentEmotion} />
			<QuoteCarousel area="quotes" quotes={currentEmotion.quotes || []} />
			<Clock area="clock" hours={emotions} time={currentEmotion} date={date} />
			<Questions area="questions" questions={currentEmotion.questions || []} />
			<Hotlines area="hotlines" hotlines={hotlines} />
		</Layout>
	);
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		allContentfulEmotion: PropTypes.shape({
			nodes: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.string,
					name: PropTypes.string,
					quotes: PropTypes.arrayOf(
						PropTypes.shape({
							id: PropTypes.string,
							author: PropTypes.string,
							body: PropTypes.shape({
								json: PropTypes.string
							})
						})
					),
					questions: PropTypes.arrayOf(
						PropTypes.shape({
							id: PropTypes.string,
							body: PropTypes.shape({
								json: PropTypes.string
							})
						})
					)
				})
			)
		}),
		allContentfulHotline: PropTypes.shape({
			nodes: PropTypes.arrayOf(PropTypes.object)
		})
	})
};

export default IndexPage;
