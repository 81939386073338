/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import styles from './layout.module.scss';
import './layout.css';

const Layout = ({ children, isHome = false, classes }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`);

	return (
		<>
			<Header siteTitle={data.site.siteMetadata?.title || `Title`} />
			<div className={styles.layoutContain}>
				<main className={className({ [styles.homeLayout]: isHome }, classes)}>
					{children}
					<footer className={styles.footer} style={{ gridArea: 'footer' }}>
						© {new Date().getFullYear()} A.S. King, built by
						{` `}
						<a href="https://www.adxsoftware.com">Aardonyx</a>
					</footer>
				</main>
			</div>
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	isHome: PropTypes.bool,
	classes: PropTypes.oneOf([
		PropTypes.string,
		PropTypes.object,
		PropTypes.array
	])
};

export default Layout;
