import React from 'react';
import classNames from 'classnames';

import SEO from '../components/seo';

import styles from '../styles/clock.module.scss';

export default function Clock({
	hours = [],
	time = {},
	date = new Date(),
	area
}) {
	const secondHand = React.useRef(null);
	const hourHand = React.useRef(null);

	const seconds = date.getSeconds() || 60;
	const minutes = date.getMinutes() || 1;

	const timePercent = minutes / 60;

	React.useEffect(() => {
		if (seconds === 60) {
			secondHand.current.style.transition = undefined;
			secondHand.current.style.transform = `rotate(0deg)`;
			setTimeout(() => (secondHand.current.style.transition = null), 100);
		}
	}, [seconds]);

	return (
		<>
			<SEO title="Emotion Clock" />
			<div className={styles.clockContainer} style={{ gridArea: area }}>
				<div className={styles.clockFace} /> {/*Clock background image*/}
				<div className={styles.clockHours}>
					{hours?.map(hour => {
						const isActive = time?.name === hour.name;
						//TODO active hour stuff stays visible after the hour has passed
						return (
							<div
								key={`${hour.name}-contain`}
								style={{ transform: `rotate(${hour.hour * 45}deg)` }}
							>
								{isActive && (
									<span
										key={`${hour.name}-diluted`}
										className={styles.dilutedHour}
									>
										{hour.dilutedName}
									</span>
								)}
								<span
									key={hour.name}
									className={classNames(styles.hour, {
										[styles.active]: isActive,
										[styles.isAnticipate]: hour.name === 'Anticipation'
									})}
								>
									{hour.name}
								</span>
								{isActive && (
									<span
										key={`${hour.name}-concentrated`}
										className={styles.concentratedHour}
									>
										{hour.concentratedName}
									</span>
								)}
							</div>
						);
					})}
				</div>
				<div
					ref={secondHand}
					className={styles.secondHand}
					style={{ transform: `rotate(${6 * seconds}deg)` }}
				/>
				<div
					ref={hourHand}
					className={styles.hourHand}
					style={{
						transform: `rotate(${45 * time?.hour - 22.5 + timePercent * 45}deg)`
					}}
				/>
				<div className={styles.screw} />
			</div>
		</>
	);
}
