import React from 'react';
import PropTypes from 'prop-types';

import { renderRichText } from 'gatsby-source-contentful/rich-text';

import styles from './Questions.module.scss';

export default function Questions({ questions, area }) {
	return (
		<div className={styles.questions} style={{ gridArea: area }}>
			<ol>
				{questions.map((question, idx) => (
					<li key={idx}> {renderRichText(question.body)}</li>
				))}
			</ol>
		</div>
	);
}
