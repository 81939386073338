import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import styles from './QuoteCarousel.module.scss';

export default function QuoteCarousel({ quotes = [], area }) {
	const [quoteIdx, setQuoteIdx] = useState(0);
	const quoteBlock = useRef(null);

	const active = (match, count) => {
		if (match === count) {
			return styles.active || 'active';
		} else if (
			match === count + 1 ||
			(count === quotes.length - 1 && match === 0)
		) {
			return styles.next || 'next';
		} else if (
			match === count - 1 ||
			(count === 0 && match === quotes.length - 1)
		) {
			return styles.before || 'before';
		}
		return null;
	};

	const makeQuote = (thisIdx, activeIdx, len) => {
		return (
			<div
				className={classNames(
					styles.quoteContainer,
					active(thisIdx, activeIdx)
				)}
				style={{ width: `${100 / len}%` }}
				ref={thisIdx === activeIdx ? quoteBlock : null}
				key={thisIdx}
			>
				<h3
					key={thisIdx}
					className={classNames(styles.quote, active(thisIdx, activeIdx))}
				>
					<div className={styles.quoteDirect}>
						{renderRichText(quotes?.[thisIdx].body)}
					</div>
				</h3>
				<h5 className={styles.author}>—{quotes?.[thisIdx].author}</h5>
			</div>
		);
	};

	return (
		<div className={styles.container} style={{ gridArea: area }}>
			<div
				className={styles.quoteBlock}
				style={{
					transform: `translateX(-${(quoteIdx / quotes?.length) * 100}%)`,
					width: `${100 * quotes?.length}%`
				}}
			>
				{quotes?.map((quote, idx) => makeQuote(idx, quoteIdx, quotes.length))}
			</div>
			{quotes?.length >= 2 && (
				<p
					className={styles.nextQuote}
					onClick={
						quoteIdx < quotes.length - 1
							? () => setQuoteIdx(quoteIdx + 1)
							: () => setQuoteIdx(0)
					}
				>
					Next Quote
				</p>
			)}
		</div>
	);
}

QuoteCarousel.propTypes = {
	quotes: PropTypes.arrayOf(
		PropTypes.shape({
			body: PropTypes.string,
			author: PropTypes.string
		})
	)
};

QuoteCarousel.defaultProps = {
	quotes: []
};
