import React from 'react';

export default function useAnimationFrame(callback) {
	const requestRef = React.useRef(null);
	const previousTimeRef = React.useRef(null);

	const animate = time => {
		if (previousTimeRef.current !== null) {
			const deltaTime = time - previousTimeRef.current;
			callback(deltaTime, time);
		}

		previousTimeRef.current = time;
		requestRef.current = requestAnimationFrame(animate);
	};

	React.useEffect(() => {
		requestRef.current = requestAnimationFrame(animate);
		return () => cancelAnimationFrame(requestRef.current);
	}, []);
}
