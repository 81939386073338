import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'gatsby-image/withIEPolyfill';
import { graphql } from 'gatsby';

import { BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Page from '../components/Page';

import styles from '../components/Page.module.scss';

Component.propTypes = {
	data: PropTypes.shape({
		contentfulPage: PropTypes.shape({
			slug: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
			body: PropTypes.shape({ raw: PropTypes.any }).isRequired,
			image: PropTypes.shape({
				fluid: PropTypes.object,
				contentful_id: PropTypes.string,
				description: PropTypes.string,
				title: PropTypes.string
			})
		}).isRequired
	}).isRequired
};

const renderOptions = {
	renderNode: {
		[BLOCKS.EMBEDDED_ASSET](node) {
			const target = node.data.target;
			return (
				<Img
					key={target.contentful_id}
					fluid={target.fluid}
					alt={target.description}
					title={target.title}
				/>
			);
		}
	}
};

export default function Component(props) {
	const { body, title, image } = props.data.contentfulPage;

	return (
		<Page
			classes={classNames(styles.container, {
				[styles.withImage]: !!image?.fluid
			})}
		>
			<h1 className={styles.pageTitle}>{title}</h1>
			<div className={styles.pageBody}>
				{renderRichText(body, renderOptions)}
			</div>
			{image?.fluid && (
				<Img
					className={styles.fluidImg}
					key={image.contentful_id}
					fluid={image.fluid}
					alt={image.description}
					title={image.title}
					objectFit="contain"
				/>
			)}
		</Page>
	);
}

export const query = graphql`
	query($id: String) {
		contentfulPage(id: { eq: $id }) {
			slug
			title
			image {
				... on ContentfulAsset {
					title
					description
					contentful_id
					fluid(maxWidth: 1600, quality: 100) {
						...GatsbyContentfulFluid
					}
				}
			}
			body {
				raw
				references {
					... on ContentfulAsset {
						__typename
						title
						description
						contentful_id
						fluid(maxWidth: 1600, quality: 100) {
							...GatsbyContentfulFluid
						}
					}
				}
			}
		}
	}
`;
