import React from 'react';
import PropTypes from 'prop-types';

import Layout from './layout';

export default function Page({ children, classes }) {
	return <Layout classes={classes}>{children}</Layout>;
}

Page.propTypes = {
	children: PropTypes.node.isRequired,
	classes: PropTypes.oneOf([
		PropTypes.string,
		PropTypes.object,
		PropTypes.array
	])
};
