import React from 'react';
import PropTypes from 'prop-types';

import styles from './Hotlines.module.scss';

const phoneLink = phone => `tel:+1-${phone.replace(/^1-/, '')}`;
const smsLink = (num, body) => `sms:${num};?&body=${encodeURIComponent(body)}`;

function Hotline({ name, phone, textPhone, textNum, textBody }) {
	return (
		<li>
			<span className={styles.name}>{name}</span>
			{(phone || textPhone) && ', '}
			{phone && (
				<a href={phoneLink(phone)} className={styles.phone}>
					{phone}
				</a>
			)}
			{textPhone && (
				<>
					{phone && ' or '}
					<a href={smsLink(textNum, textBody)} className={styles.phone}>
						{textPhone}
					</a>
				</>
			)}
		</li>
	);
}

const hotlineTypes = {
	name: PropTypes.string,
	phone: PropTypes.string,
	textPhone: PropTypes.string,
	textNum: PropTypes.number,
	textBody: PropTypes.string,
	ordinal: PropTypes.number
};

Hotline.propTypes = hotlineTypes;

export default function Hotlines({ hotlines, area }) {
	return (
		<div className={styles.hotlines} style={{ gridArea: area }}>
			<h5>Hotlines</h5>
			<ul>
				{hotlines?.map((hotline, idx) => (
					<Hotline key={idx} {...hotline} />
				))}
			</ul>
		</div>
	);
}

Hotlines.propTypes = {
	hotlines: PropTypes.arrayOf(PropTypes.shape(hotlineTypes))
};
