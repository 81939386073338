import React from 'react';

import styles from './CurrentTime.module.scss';

export default function CurrentTime({ currentEmotion, area }) {
	return (
		<div className={styles.timeNow} style={{ gridArea: area }}>
			<h2 style={{ borderLeft: `12px solid ${currentEmotion.color}` }}>
				The time is now{' '}
				<span className={styles.currentEmotion}>{currentEmotion?.name}</span>
			</h2>
		</div>
	);
}
