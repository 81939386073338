import React from 'react';
import PropTypes from 'prop-types';

import Nav from './nav';

import styles from './header.module.scss';

const Header = () => (
	<header className={styles.header}>
		<Nav />
	</header>
);

Header.propTypes = {
	siteTitle: PropTypes.string
};

Header.defaultProps = {
	siteTitle: ``
};

export default Header;
