import React from 'react';

import { Link, graphql, useStaticQuery } from 'gatsby';

import styles from './nav.module.scss';

const Nav = () => {
	const data = useStaticQuery(graphql`
		query NavQuery {
			allContentfulPage(sort: { fields: id, order: ASC }) {
				nodes {
					title
					id
					slug
					mainNav
				}
			}
		}
	`);

	return (
		<nav className={styles.nav}>
			<ul>
				<li>
					<Link to={`/`}>Home</Link>
				</li>
				{data.allContentfulPage.nodes.map(page => {
					if (!page.mainNav) return;
					return (
						<li key={page.id}>
							<Link to={`/${page.slug}`}>{page.title}</Link>
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

export default Nav;

//TODO need some way to dynamically generate the Gatsby pages
